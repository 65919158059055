import { render, staticRenderFns } from "./SearchModal.vue?vue&type=template&id=40d705e2&scoped=true&lang=pug&"
import script from "./SearchModal.vue?vue&type=script&lang=js&"
export * from "./SearchModal.vue?vue&type=script&lang=js&"
import style0 from "./SearchModal.vue?vue&type=style&index=0&id=40d705e2&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d705e2",
  null
  
)

export default component.exports