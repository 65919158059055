export default {
  data: () => ({
    modalEvent: 'modal-event',
    modalName: 'modal-name',
    width: '50%',
    mounted: false,
    visible: false,
    params: null
  }),

  beforeDestroy() {
    // this.$root.$off([this.modalEvent])
    this.$root.$off(this.modalEvent, this.onModalEventHandler)
    window.removeEventListener('resize', this.resizeModal, false)
    this.enableScrolling()
    this.$modal.hide(this.modalName)
    this.visible = false
  },

  created() {
    if (this.$device.isTablet) this.width = '80%'
  },

  mounted() {
    this.mountComponent()
  },

  methods: {
    mountComponent() {
      if (!this.modalEvent || !this.modalName) return
      this.$root.$on(this.modalEvent, this.onModalEventHandler)
      window.addEventListener('resize', this.resizeModal, false)
      this.mounted = true
    },

    resizeModal() {
      this.$root.$emit('calc-view-port-units')
    },

    onModalEventHandler(param) {
      this.params = param || null
      this.disableScrolling()
      this.$modal.show(this.modalName)
      this.visible = true
      this.$nextTick(this.onModalMounted)
    },

    disableScrolling() {
      const html = document.getElementsByTagName('html')[0]
      html.classList.add('stop-scrolling')
    },

    enableScrolling() {
      const html = document.getElementsByTagName('html')[0]
      html.classList.remove('stop-scrolling')
      this.$root.$emit('modal-closed', { modalName: this.modalName })
    },

    onCloseHandler() {
      if (this.modalName === 'education-video') {
        this.$root.$emit('close-education-video')
      }
      this.beforeModalClosed()
      this.$modal.hide(this.modalName)
      this.visible = false
    },

    onModalMounted() {},
    beforeModalClosed() {}
  }
}
