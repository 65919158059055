<template lang="pug">
  modal.search-modal(v-show="mounted", ref="modal", transition="modal", :max-width="1200", :width="width", :name="modalName", :adaptive="true", height="auto", @closed="enableScrolling")
    //.modal-header
      //.close-button.is-flex(@click="onCloseHandler")
      //  span {{ $t('common.close') }}
      //  icon(iconName="close", iconColor="#2e2e2e")
    .modal-body.search
      .search__section
        .close-button(@click="onCloseHandler")
          icon(iconName="close", iconColor="#ffffff")
        .search__quick-search
          QuickSearch

</template>

<script>
import modalMixin from '~~/mixins/modalMixin'

export default {
  name: 'SearchModal',

  components: {
    QuickSearch: () =>
      import('~~/components/Layout/TopHeader/QuickSearch/QuickSearch')
  },

  mixins: [modalMixin],

  data: () => ({
    modalEvent: 'search-modal',
    modalName: 'search-modal',
    width: '50%',
    params: {}
  }),

  mounted() {
    this.$root.$on('close-modal-search', this.closeModalSearch)
  },

  beforeDestroy() {
    this.$root.$off('close-modal-search', this.closeModalSearch)
  },

  methods: {
    closeModalSearch() {
      this.onCloseHandler()
    },

    onModalEventHandler(params) {
      this.params = params || {}
      this.disableScrolling()
      this.$modal.show(this.modalName)
      this.visible = true
      this.$nextTick(this.onModalMounted)
    },

    onSubmitFinish() {
      this.onCloseHandler()
      this.$sweetalert.insert({
        title: this.$t('detailsPage.question.swal.title'),
        text: this.$t('detailsPage.question.swal.text'),
        type: 'success',
        confirmButtonText: this.$t(
          'detailsPage.question.swal.confirmButtonText'
        )
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.v--modal-overlay
  overflow: auto
  z-index: 10000
  background-color: rgba(0, 0, 0, .7)
.search-modal
  ::v-deep
    .v--modal-box
      background: transparent
      box-shadow: none
      top: 144px!important
      left: 50%!important
      transform: translateX(-50%)!important
      max-width: 600px!important
      width: 100%!important
      +touch
        max-width: 100%!important
        top: 60px!important
    .search__section
      position: relative
    .search__quick-search
      max-width: 557px
      width: 100%
      position: relative
      +touch
        max-width: 100%
      & input
        width: 100%
    .quick-search
      padding: 0
      flex-direction: column
    .quick-search-input
      background: #ffffff
      color: #111
      border: 1px solid #bababa
      padding: 0 0 0 40px
      line-height: 20px
      font-size: 1rem
      font-family: inherit
      width: 100%
      text-align: left
      input
        padding: 13px 17px 13px 0
    .quick-search-icon
      position: absolute
      top: 50%
      left: 11px
      transform: translateY(-50%)
      border: none
      padding: 0
      background-color: transparent
      margin: 0
    .quick-search__border-bottom
      display: none
    .quick-search-wrapper
      width: 100%
      position: relative
    .quick-search-dropdown__wrapper
      position: relative
      flex: 1 0 100%
      max-width: 100%
      max-height: 70vh
      overflow: hidden
      z-index: 17
    .quick-search-dropdown
      border: 1px solid #ccc
      border-top: none
      background-color: #fff
      width: 100%

.modal-header
  position: relative
  height: 30px
.close-button
  cursor: pointer
  position: absolute
  right: 7px
  top: 15px
  margin-left: 25px
  +touch
    position: initial
    text-align: end
    padding-bottom: 15px
  svg
    height: 15px
    width: 15px
.search
  +touch
    padding: 20px 30px
  &__quick-search
    width: 100%
    order: 1
    align-items: center
  &-item
    margin-top: 20px
    padding: 30px 30px 24px 30px
    background-color: $secondary-bg-color
    flex-wrap: wrap
    +mobile
      padding: 20px
      flex-direction: column
      gap: 15px
    &__content
      width: 100%
      column-gap: 37px
      +mobile
        column-gap: 20px
    &-params
      +mobile
        width: 100%
    &-price
      font-family: $lato
      font-weight: 700
      font-size: 18px
      line-height: 22px
      width: 100%
      padding-top: 16px
      justify-content: left
      text-align: center
      &-total
        margin-top: 23px
        padding-top: 24px
        border-top: 1px solid $border-color
        font-size: 18px
        font-weight: 700
        width: 100% !important
      &__modifier
        text-align: left
        border-top: none
    &-image
      max-width: 196px
      width: 100%
      +touch
        max-width: 160px
      +mobile
        max-width: 120px
      ::v-deep
        img
          border: 1px solid $border-color
    &-params
      &.bracelet,
      &.earring,
      &.ring,
      &.necklace,
      &.pendant,
      &.stone,
      &.pair
        max-width: 300px
        width: 100%
        +mobile
          width: 100%
    &-param
      justify-content: space-between
      &:not(:last-child)
        margin-bottom: 5px
      > div
        width: 50%
      .param-label
        font-family: $lato
        font-weight: 700
        font-size: 14px
        line-height: 20px
        color: $dark-green
      .param-value
        font-family: $lato
        font-weight: 400
        font-size: 14px
        line-height: 17px
        padding-left: 5px
  &-company
    margin-top: 24px
    padding: 30px
    background-color: $light-primary-color
  &-form
    margin-top: 10px
</style>
